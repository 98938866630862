<template>
  <div class="model-generator">
    <div class="row">
      <div class="col-12 col-lg-6 right">
        <div class="form-group">
          <label :for="`${component.name}Query`">
            <span class="color-purple">*</span>
            <span> 생성 쿼리</span>
          </label>
          <textarea :id="`${component.name}Query`" class="form-control" v-model="state.query" :placeholder="placeholder" @keyup.ctrl.enter="generate()"></textarea>
        </div>
        <div class="acts">
          <button type="button" class="btn btn-primary btn-lg btn-block" @click="generate()" :disabled="!state.query.trim()">변환하기</button>
        </div>
      </div>
      <div class="col-12 col-lg-6 right">
        <div class="form-group">
          <label :for="`${component.name}Result`">
            <span> 자바 모델</span>
          </label>
          <textarea :id="`${component.name}Result`" class="form-control" v-model="state.result" placeholder="쿼리 입력 후 변환하기 버튼을 눌러주세요."></textarea>
        </div>
        <div class="acts">
          <button type="button" class="btn btn-secondary btn-lg btn-block" @click="copy()" :disabled="!state.result.trim()">복사하기</button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import {defineComponent, reactive} from "@vue/composition-api";
import mixin from "@/scripts/mixin";
import store from "@/scripts/store";
import lib from "@/scripts/lib";

function Component(initialize) {
  this.name = "pageAdminUtmGenerator";
  this.initialize = initialize;
}

export default defineComponent({
  mixins: [mixin],
  setup() {
    const component = new Component(() => {
    });

    const state = reactive({
      query: "",
      result: "",
    });

    const placeholder = [`ex) create table members`, "(", "    id   int null,", "    name varchar(100) null", ")"].join("\n");

    const generate = () => {
      const rows = state.query.split("\n").filter(q => q);
      const results = ["import lombok.Getter;", "import lombok.Setter;", "import javax.persistence.Entity;", "import javax.persistence.Column;"];

      const entity = {
        tableName: "",
        className: "",
        columns: []
      };

      let inBracket = false;

      for (let i = 0; i < rows.length; i += 1) {
        const r = rows[i];

        if (r.includes("create table ")) {
          entity.tableName = r.replace("create table ", "").trim();
          entity.className = lib.getPascalCase(entity.tableName.replace("tb_omc_", ""));
        } else if (r.trim().startsWith("(")) {
          inBracket = true;
        } else if (r.trim().startsWith(")")) {
          inBracket = false;
        }

        if (r[0] !== " " || r.trim().startsWith("(") || r.trim().startsWith("primary key") || !inBracket) {
          continue;
        }

        const words = r.split(" ").filter(x => x);
        let [name, type] = words;

        switch (type) {
          case "int":
          case "char":
            break;

          case "bigint":
            type = "long";
            break;

          case "datetime": {
            const imp = "import java.time.LocalDateTime;";
            !results.includes(imp) && (results.push(imp));
            type = "LocalDateTime";
            break;
          }

          default:
            if (type.includes("varchar") && type.includes("(1)")) {
              type = "char";
            } else {
              type = "String";
            }
            break;
        }

        entity.columns.push({
          name, type
        });
      }

      results.push(...["", "@Getter", "@Setter", `@Entity(name = "${entity.tableName}")`, `public class ${entity.className} {`]);

      for (let c of entity.columns) {
        results.push("");
        // results.push(`  @Column(name = "${c.name}")`);
        results.push(`  private ${c.type} ${lib.getCamelCase(c.name)};`);
      }

      results.push("");
      results.push("}");

      state.result = results.join("\n");
    };

    const copy = () => {
      const $textarea = document.getElementById(`${component.name}Result`);
      $textarea.select();
      document.execCommand("copy");
      store.commit("setSwingMessage", "자바 모델 값을 복사하였습니다.");
    };

    return {component, state, placeholder, generate, copy};
  }
});
</script>

<style lang="scss" scoped>
.model-generator {
  > .row > div {
    height: 700px;
    position: relative;
    margin-bottom: 15px;

    textarea {
      height: calc(100% - 100px);
      position: absolute;
      width: calc(100% - 30px);
      left: $px15;
      top: $px31;
      resize: none;
    }

    .acts {
      position: absolute;
      left: $px15;
      bottom: 0;
      width: calc(100% - $px30);

      .btn {
        &.copy {
          position: absolute;
          top: 0;
          right: 0;
          width: $px51;
          height: 100%;
          margin: 0;
        }
      }
    }

    .form-group {
      > label {
        font-size: $px14;
      }
    }
  }

  @media(max-width: 991px) {
    > .row > div {
      .acts {
        position: static;
        padding-right: 0;
        width: 100%;

        .btn {
          position: static;
          width: calc(100% - 60px);
          display: inline-block;

          &.copy {
            position: static;
          }
        }
      }

      textarea {
        position: static;
        width: 100%;
        min-height: $px200;
      }
    }
  }
}
</style>