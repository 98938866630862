import { render, staticRenderFns } from "./ModelGenerator.vue?vue&type=template&id=cd6064f4&scoped=true&"
import script from "./ModelGenerator.vue?vue&type=script&lang=js&"
export * from "./ModelGenerator.vue?vue&type=script&lang=js&"
import style0 from "./ModelGenerator.vue?vue&type=style&index=0&id=cd6064f4&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "cd6064f4",
  null
  
)

export default component.exports